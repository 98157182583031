@font-face {
  font-family: "barlow";
  src: url("assets/fonts/Barlow-SemiBoldItalic.ttf");
}

@font-face {
  font-family: "Monserrat";
  src: url("assets/fonts/Montserrat-VariableFont_wght.ttf");
}

body {
  // overflow: none;
  margin: 0;
  padding: 0;
  background-color: #e9f4f6 !important;
}

html {
  height: 100%;
  background-color: #e9f4f6 !important;
  font-family: "Monserrat", sans-serif;
}

.card-add-new {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 25px auto !important;
  padding: 15px;
  width: 200px;
  height: 120px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.5s;
  border: 1px #2c4697 solid;
  color: #2c4697;
  border-radius: 10px;

  .icon-plus {
    width: 100% !important;
    height: auto !important;
    font-size: 50px;
  }

  &:hover {
    background-color: #2c4697;
    color: white;
  }
}

.card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 25px auto !important;
  padding: 15px;
  width: 200px;
  height: 120px;
  background: #fff;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 10px;
}

.card:hover {
  background-color: #2c4697;
  color: white !important;
  mat-icon {
    color: white !important;
  }
  mat-label {
    color: white !important;
  }
  button {
    border: 1px solid white !important;
    color: white !important;
  }
}

.late-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background: red;
  border-top-left-radius: 10px;
  clip-path: polygon(0 0, 100% 0, 0 100%);
}

.background-image-login {
  background-image: url(../src/assets/fondos/fondoTEcnoAir.svg) !important;
  background-repeat: no-repeat;
  background-position: center !important;
  background-size: cover;
}

.container {
  display: flex;
}

.vertical-center {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.center {
  text-align: center;
  margin: 0 auto;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-around {
  justify-content: space-around !important;
}

.align-items-center {
  align-items: center;
}

.align-content-center {
  align-content: center;
  flex-wrap: wrap;
}

//width
each(range(1%, 100%, 1), {
  .w-@{index} {
    width: @value;
  }
});

//height
each(range(1%, 100%, 1), {
  .h-@{index} {
    height: @value;
  }
});

//heightvh
each(range(1vh, 100vh, 1), {
  .hvh-@{index} {
    height: @value;
  }
});

//margin

each(range(1px, 100px, 1), {
  .m-@{index} {
    margin: @value;
  }
});

//margin top

each(range(1px, 100px, 1), {
  .mt-@{index} {
    margin-top: @value;
  }
});

//margin rigth

each(range(1px, 100px, 1), {
  .mr-@{index} {
    margin-right: @value;
  }
});

//margin left

each(range(1px, 100px, 1), {
  .ml-@{index} {
    margin-left: @value;
  }
});

//margin bottom

each(range(1px, 200px, 1), {
  .mb-@{index} {
    margin-bottom: @value;
  }
});

//general margin
each(range(1px, 100px, 1), {
  .m-@{index} {
    margin: @value;
  }
});

//padding

each(range(1px, 100px, 1), {
  .p-@{index} {
    padding: @value;
  }
});

each(range(1px, 100px, 1), {
  .pt-@{index} {
    padding-top: @value;
  }
});

.list {
  flex-direction: column !important;
  display: flex;
}

.content-right {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.content-left {
  justify-content: flex-start;
  text-align: left;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.justify-content-baseline {
  justify-content: baseline;
}

.grid {
  display: flex;
  flex-wrap: wrap;
}

.primary-button-disable {
  opacity: 0.6;
  cursor: not-allowed !important;
}

.row {
  flex-direction: row;
  display: flex;
}

.col {
  flex-direction: column;
  display: flex;
}

//font size

h1 {
  font-size: 30px;
}

h2 {
  font-size: 32px;
  margin: 10px;
}

h3 {
  font-size: 18px;
  margin: 5px !important;
  font-weight: normal !important;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 20px;
  margin: 1px;
}

label {
  font-size: 22px;
  font-weight: bold;
}

input {
  font-size: 22px;
}

p {
  font-size: 22px !important;

  b {
    font-size: 22px;
  }
}

.bold-text {
  font-weight: bold;
}

.normal-text {
  font-weight: normal;
}

.home {
  mat-label {
    font-size: 22px !important;
    color: #2c4697;
  }
}

.person-card {
  mat-label {
    font-size: 16px !important;
  }

  .name {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
}

//form
.mat-mdc-form-field {
  width: 90%;
  margin: 0 auto;
  font-family: "Della Respira";
  max-width: 350px;
}

.invalid-input {
  border: 1px solid red !important;
}

.white-bg {
  background-color: #fafafa;
}

.postion-icon-card {
  position: relative !important;
  justify-content: flex-start !important;
  display: flex !important;
  .icon-cards {
    background-color: transparent !important;
    color: black !important;
    border: none !important;
    cursor: pointer;
    transition: transform 0.5s;
    &:hover {
      background-color: transparent !important;
      transform: scale(1.2);
    }
  }
}

.icon-button {
  cursor: pointer !important;
  border: none !important;
  background-color: #8fb3bb !important;
  color: white !important;
  border-radius: 5px !important;
}

.img-logo {
  width: 100%;
  max-width: 200px;
}

.card-wrapper {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 25%));
  grid-gap: 15px;
}

.text-card-checkbox {
  font-size: 18px;
  font-weight: normal;
  right: 10px;
  position: relative;
  top: 3px;
}

.not-show {
  display: none;
}

.consecutive-position {
  font-family: "barlow" !important;
  position: absolute;
  right: 11%;
  top: 90px;
  width: fit-content;
  border-color: black;
  border: 1px solid;
  padding: 0px 5px;
  border-radius: 5px;
}

.download-btn-position {
  position: absolute;
  right: 11%;
  top: 135px;
}

.photo-btn-position {
  position: absolute;
  right: 11%;
  top: 150px;
}

//snack bar

.snackbar-error {
  border: 3px solid rgb(207, 3, 3);
  background-color: rgb(207, 3, 3) !important;
  color: white !important;
  border-radius: 10px !important;
  font-weight: bold;

  .mat-simple-snackbar-action {
    color: white;
  }
}

.snackbar {
  border: 3px solid rgb(81, 207, 3);
  background-color: rgb(81, 207, 3) !important;
  color: black !important;
  border-radius: 10px !important;
  font-weight: bold;

  .mat-simple-snackbar-action {
    color: black;
  }
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: transparent !important;
}

.close-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}

.loading-icon {
  position: absolute;
  left: 50vw;
  top: 50vh;
}

.details {
  border: 1px solid #2c46b2;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
}

.loading-icon-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -10vw;
  width: 100%;
}

.loading {
  opacity: 50%;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

//responsive

@media (max-width: 1560px) {
  each(range(1%, 100%, 1), {
    .w-s-@{index} {
      width: @value;
    }
  });

  each(range(1%, 100%, 1), {
  .h-s-@{index} {
    height: @value;
  }
});

  .card-wrapper {
    grid-template-columns: repeat(3, minmax(0, 33%));
  }
}

@media (max-width: 1100px) {
  h2 {
    font-size: 28px;
  }

  h4 {
    font-size: 19px;
  }

  .icon-button {
    width: 30px !important;
    height: 30px !important;
    padding: 3px !important;
    font-size: 14 !important;
    bottom: 5px;
  }

  .card-wrapper {
    grid-template-columns: repeat(2, minmax(0, 50%));
    grid-gap: 5px;
  }
}

@media (max-width: 1000px) {
  each(range(1%, 100%, 1), {
    .w-xs-@{index} {
      width: @value;
    }
  });

  each(range(1%, 100%, 1), {
  .h-xs-@{index} {
    height: @value;
  }
});

  .m-xs-0 {
    margin: 0px !important;
  }

  .center-xs {
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }

  .list-xs {
    flex-direction: column;
  }
}

@media (max-width: 800px) {
  h1 {
    font-size: 25px;
  }
  h2 {
    font-size: 25px;
  }

  h4 {
    font-size: 15px;
  }

  h5 {
    font-size: 16px;
  }
}

@media (max-width: 700px) {
  .card-wrapper {
    grid-template-columns: repeat(1, minmax(0, 100%));
  }
}

@media (max-width: 650px) {
  .wrapper {
    grid-template-columns: repeat(1, auto) !important;
  }

  .client-wrapper {
    grid-template-columns: repeat(1, minmax(0, 90%)) !important;
    grid-gap: 0px;
  }
}

@media (max-width: 640px) {
  .wrapper-c {
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-gap: 15px;
  }
}

@media (max-width: 600px) {
  each(range(1, 100, 1), {
    .mt-xs-@{index} {
      width: @value;
    }
  });

  .ml-xs-0 {
    margin-left: 0px;
  }

  .align-items-center-xs {
    align-items: center;
  }

  .icon-button {
    bottom: 0px;
  }

  .justify-content-center-xs {
    justify-content: center;
  }

  .m-0 {
    margin: 0px !important;
  }

  .download-btn-position {
    right: 21.5%;
    top: 90px;
  }
}

@media (max-width: 500px) {
  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 20px;
  }

  label {
    font-size: 15px;
  }

  h4 {
    font-size: 12px;
  }

  h3 {
    font-size: 13px;
  }

  h5 {
    font-size: 15px;
  }

  .consecutive-position {
    right: 6%;
  }

  .download-btn-position {
    right: 6%;
    top: 135px;
  }

  each(range(1%, 100%, 1), {
    .w-xxs-@{index} {
      width: @value;
    }
  });

  each(range(1px, 100px, 1), {
  .mt-xxs-@{index} {
    margin-top: @value;
  }
});

  .home {
    mat-label {
      font-size: 20px !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .mdc-line-ripple::before {
    width: 65% !important;
  }

  .mdc-line-ripple::after {
    width: 65% !important;
  }

  .mt-30 {
    margin-top: 30px !important;
  }

  .icon-button {
    bottom: 8px;
  }
}

@media (max-width: 430px) {
  p {
    font-size: 20px;
  }
}

@media (max-width: 400px) {
  each(range(1px, 100px, 1), {
    .mt-xxxs-@{index} {
      width: @value;
    }
  });

  .mt-30 {
    margin-top: 30px !important;
  }

  h5 {
    font-size: 9px;
  }

  .icon-button {
    width: 25px !important;
    height: 25px !important;
    padding: 0px !important;
    font-size: 8 !important;
    bottom: 8px;
  }

  .text-card-checkbox {
    font-size: 14px;
    right: 6px;
  }

  .text-card-checkbox {
    font-size: 14px;
    right: 6px;
  }
}

//wrapper
.wrapper {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 15px;
}

.client-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 60%));
  grid-gap: 15px;
}

.justify-content {
  justify-content: center;
}

//width input

.mdc-line-ripple::before {
  width: 75% !important;
}

.mdc-line-ripple::after {
  width: 75% !important;
}

// ============ SCROLLBAR ============
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: #1577be !important;
  border: 0.5px solid #1577be !important;
  border-radius: 8px;
}

.show-button {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 10%;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

//material

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  width: 100%;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 15px;
  top: 35px !important;
}
.example-spacer {
  flex: 1 1 auto;
}
.navbar-logo {
  cursor: pointer;
  position: relative;
  width: 60px;
  height: 60px;
}

//to pdf

.no-scroll {
  overflow: hidden !important;
  touch-action: none;
}

.pdfContainer {
  display: none;
  h2 {
    font-size: 25px;
  }

  h4 {
    font-size: 20px;
  }

  span {
    font-size: 20px;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 20px;
    margin: 0px !important;
  }

  .pdfInfo {
    position: relative;
    top: 17px;

    h5 {
      font-size: 22px;
    }
  }

  .logo-pdf {
    width: 200px;
    height: 200px;
  }

  .wrapper-pdf {
    margin: 0 40px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 15px;
  }

  .wrapper-options {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 15px;
  }

  .wrapper-subtask {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 15px;
  }

  table {
    width: 80%;
    h2 {
      font-size: 20px;
    }

    p {
      font-size: 15px;
    }
  }

  .mat-table {
    min-width: 50% !important;
  }
  tr,
  td {
    width: 25%;
    text-align: left;
    border: 1px solid #000;
    font-size: 17px;
  }

  .nostyles {
    tr,
    td {
      border: none !important;
    }
  }

  .consecutive {
    border-color: black;
    border: 1px solid;
    padding: 0px 5px;
    border-radius: 5px;
  }
}

::ng-deep .mdc-data-table__table {
  min-width: 50% !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 24px !important;
}

.mdc-data-table__row:last-child .mdc-data-table__cell {
  border: 1px solid black !important;
}
